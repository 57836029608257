import * as Sentry from "@sentry/nuxt";

Sentry.init({
    dsn: "https://1b6fc07140e95fc69ea01e6705917e63@o4507535830155264.ingest.us.sentry.io/4508016930521088",
    tracesSampleRate: 1.0,
    // replaysSessionSampleRate: 0.0,
    // replaysOnErrorSampleRate: 1.0,
    // integrations: [
    //     Sentry.replayIntegration({
    //         // Additional SDK configuration goes in here, for example:
    //         maskAllText: true,
    //         blockAllMedia: true,
    //     }),
    // ],
});
